<template>
  <div class="orderManagement-view">
    <el-tabs v-model="activeName"  @tab-click="handleClick">
      <el-tab-pane label="订单列表" name="first">
        <orderList v-if="activeName == 'first'" />
      </el-tab-pane>
      <el-tab-pane label="退款/售后" name="second">
        <afterSale v-if="activeName == 'second'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import orderList from "./components/orderList";
import afterSale from "./components/afterSale";
export default {
  name: "orderManagement",
  components: {
    orderList,
    afterSale,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style scoped lang='sass'>
</style>
